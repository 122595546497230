<template>
  <div id="staffMemberView">
    <div class="container">
      <StaffListItem
        v-if="staffMemberData"
        :staff-list-item="staffMemberData"
        @loaded="staffMemberLoaded"
      />
    </div>
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import { mapMutations } from 'vuex'
import StaffListItem from '@/components/Staff/StaffListItem'

export default {
  name: 'StaffMember',
  components: { StaffListItem },
  data() {
    return {
      staffMemberData: null,
    }
  },
  mounted() {
    this.loadStaffMember()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadStaffMember() {
      const slug = this.$route.params.slug
      this.setStaffMemberData(
        helpers.readStoredResponse(`view.staff-member.${slug}`)
      )

      api.views.loadStaffMember(slug).then((response) => {
        this.setStaffMemberData(response)
      })
    },
    setStaffMemberData(response) {
      if (!response || !response.entry) return

      this.staffMemberData = response.entry
    },
    staffMemberLoaded() {
      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
#staffMemberView {
  @apply bg-black;
  margin-bottom: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
